import React, { useState } from 'react';
import { addLink } from './firebaseauth';
import PasteFromClipboard from './components/PasteFromClipboard';
import ScrapedDataDisplay from './components/ScrapedDataDisplay';

const Scraper = ({onClose}) => {
  const [url, setUrl] = useState('');
  const [images, setImages] = useState(null);
  const [videos, setVideos] = useState(null);
  const [error, setError] = useState('');
  const [shadowDisplay,setShowDisplay]=useState(false);
  const [shadowDisplaySc,setShowDisplaySc]=useState(null);
  const [loading, setLoading] = useState('');
  console.log(loading)


  const handlclose=()=>{
    setShowDisplay(!shadowDisplay)
    setValidCode(false) 

  }
  const handleScrape = async () => {
    setLoading(true);
    try {
      const response = await fetch(`https://fastify-serverless-function-sand-one.vercel.app/api/scrap?url=${url}`);
      const data = (await response.json())
      
      setImages(data.images);
      setVideos(data.videos);
      setError('');

      const transformUrl = (url) => {
        return url.replace(/\/\d5{2}x\//, '/originals/');
      };
      if(data.images.length > 0 || data.videos.length>0){
        setShowDisplay(true);
        if(data.images[0].src){
          addLink(transformUrl(data.images[0].src))
        }
      }

    } catch (err) {
      setError('Failed to fetch data');
      console.error('Error during API call:', err);
    } finally {
      setLoading(false);
      setValidCode(true)
    }
  };
  const handlScope= async (url)=>{
    setLoading(true);
    try {
      const response = await fetch(`http://localhost:3001/scrape-content?url=${url}`);
      const data = (await response.json())

      setShowDisplaySc(data)
      
      setValidCode(true) 
      } catch (err) {
      setError('Failed to fetch data');
      console.error('Error during API call:', err);
      setValidCode(false) 


    } finally {
      setLoading(false);
    }
  }
  const [validCode,setValidCode]=useState();
  const [colorCode,setcolorCode]=useState('red');
  console.log(validCode)
  const handleInputLink=(ev)=>{

    const Rawinput=ev.target.value+"";
    setUrl(Rawinput);

    if (Rawinput.includes("visual-search")) {
        handlScope(Rawinput)
        setShowDisplay(true);

    }
    setcolorCode(Rawinput.includes("visual-search") ? 'indigo':'red')
  }
  return (
    <>
     <div className="absolute z-40 bg-gray-800 text-white p-6 w-full rounded-lg shadow-lg max-w-4xl mx-auto  ">
      <div className={`absolute top-1 left-2 bg-${colorCode}-500 w-8 h-8 rounded-full`}></div>
      <div className="mb-4 flex ">
        <input
          type="text"
          value={url}
          onChange={(e) => {handleInputLink(e)}}
          placeholder="Enter webpage URL"
          style={{width:'55rem'}}
          className="w-56 text-gray-300 p-3 border border-gray-600 rounded-lg focus:outline-none focus:border-indigo-500"
        />
        <button
          onClick={handleScrape}
          className="m-3 px-6 py-2 bg-indigo-600 text-white font-semibold rounded-lg hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500"
        >
          Scrape
        </button>
        <PasteFromClipboard onPaste={(param)=>{setUrl(param)}}/>
      </div>

      {error && (
        <div className="bg-red-600 p-4 rounded-lg mb-4">
          <p>{error}</p>
        </div>
      )}

{shadowDisplay &&

<div className="mb-8">

<h2 className="text-xl font-bold mb-4">Images</h2>
{images && images.length > 0 ? (
  <ul className="grid grid-cols-2 sm:grid-cols-3 lg:grid-cols-4 gap-4">
    {images.map((img, index) => (
      
      <li key={index} className="relative group">

        <img
          src={img.src}
          alt={`Scraped  ${index}`}
          className="w-full h-auto object-cover rounded-lg shadow-md transition-transform duration-300 ease-in-out group-hover:scale-105"
        />
      </li>
    ))}
  </ul>
) : (
  <p className="text-gray-400">No images found</p>
)}
      <ScrapedDataDisplay data={shadowDisplaySc} setUrl={setUrl} scrap={handleScrape}/>
</div>}

{shadowDisplay && <div>
<h2 className="text-xl font-bold mb-4">Videos</h2>
{videos && videos.length > 0 ? (
  <ul className="grid grid-cols-2 sm:grid-cols-3 lg:grid-cols-4 gap-4">
    {videos.map((vid, index) => (
      <li key={index} className="relative group">
                              {console.log(vid)}

        <video
          controls
          className="w-full h-auto rounded-lg shadow-md transition-transform duration-300 ease-in-out group-hover:scale-105"
        >
          <source src={vid} type="video/mp4" />
          Your browser does not support the video tag.
        </video>
      </li>
    ))}
  </ul>
) : (
  <p className="text-gray-400">No videos found</p>
)}
</div>
}
{(shadowDisplay) && <button onClick={()=>handlclose()}>Close</button>}
      
    </div>
    </>
   
  );
};

export default Scraper;
