import React, { useState } from 'react';

const PasteFromClipboard = ({ onPaste }) => {
  const [image, setImage] = useState(null);
  const [text, setText] = useState('');
console.log(image+text)
  const handlePaste = (event) => {
    event.preventDefault(); // Prevent the default paste behavior
    const items = event.clipboardData.items;

    for (let i = 0; i < items.length; i++) {
      const item = items[i];

      // Check for images
      if (item.kind === 'file' && item.type.startsWith('image/')) {
        const file = item.getAsFile();
        const reader = new FileReader();

        reader.onload = (e) => {
          setImage(e.target.result);
          if (onPaste) {
            onPaste(e.target.result); // Call the onPaste function with the image data
          }
        };

        reader.readAsDataURL(file);
      }
      
      // Check for text
      if (item.kind === 'string' && item.type === 'text/plain') {
        item.getAsString((str) => {
          setText(str);
          if (onPaste) {
            onPaste(str); // Call the onPaste function with the text data
          }
        });
      }
    }
  };

  return (
    <div
      className="w-80 h-12 border-gray-300 p-2 text-center rounded-lg"
      onPaste={handlePaste}
    >
      <p className="text-gray-600">Press Ctrl+V to paste an image or text</p>
    </div>
  );
};

export default PasteFromClipboard;
