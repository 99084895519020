import { Suspense, useRef, useState, useEffect } from 'react';
import './App.css';
import LandingPage from './LandingPage';
import GoogleOneTap from './components/GoogleOneTap';
import Matrix from './components/Matrix';

function App() {
  const [currentUser, setCurrentUser] = useState(true);
  const keyAccess = "the very beginning";
  const currentCode = useRef("");
  const [loadPage, setLoadPage] = useState(false);

  // Handle the keypress logic using useEffect to prevent multiple event listeners.
  useEffect(() => {
    const handleKeyUp = (eve) => {
      currentCode.current += eve.key;
      if (currentCode.current === keyAccess) {
        setCurrentUser((prev) => !prev); // Toggle currentUser state
      }
      console.log(currentCode.current);
    };

    window.addEventListener("keyup", handleKeyUp);
    return () => {
      window.removeEventListener("keyup", handleKeyUp); // Cleanup on unmount
    };
  }, [keyAccess]);

  function getStarted() {
    setLoadPage(true);
  }

  const MainPage = () => (
    <div className="min-h-screen page-bg via-purple-500 to-pink-500 text-white relative">
      <nav className='absolute top-12 right-56'>

        <ul className='grid grid-cols-4 gap-2'>
          <li><a className='hover:border-b hover:border-b-gray-300' href='/#'>Home</a> </li>
          <li><a className='hover:border-b  hover:border-b-gray-300' href='/#'>Contact</a> </li>
          <li><a className='hover:border-b  hover:border-b-gray-300' href='/#'>Gallery</a> </li>
          <li><a className='hover:border-b  hover:border-b-gray-300' href='/#'>About</a> </li>
        </ul>

      </nav>
      {/* Hero Section */}
      <header className="w-full flex flex-col items-center justify-center text-center py-56 -ml-80">
        <h1 className="text-5xl font-extrabold leading-snug">
          Welcome to <span className="gradient-text">Modern</span> Gallery
        </h1>

        <p className="mt-4 text-lg max-w-2xl">
          <span className='p-2 bg-gray-800 rounded'>Discover beautiful collections and creative ideas in one place.</span><br />
          <span className='p-2 bg-gray-800 rounded'>Get inspired, explore, and share with the world.</span>
        </p>
        <button
          className="mt-8 px-8 py-3 bg-white text-indigo-500 font-semibold rounded-lg shadow-md hover:bg-gray-100 transition"
          onClick={() => { getStarted(); window.scrollTo({ top: 700, behavior: 'smooth' }) }}
        >
          Get Started
        </button>
      </header>

      {/* Footer Section */}
      <footer className="w-full py-2 bg-gray-900 text-gray-400 fixed bottom-0 opacity-80">
        <div className="container mx-auto text-center">
          <p className="mb-4">© 2024 Your Website. All rights reserved.</p>
          <div className="flex justify-center space-x-6">
            <a href="/#" className="hover:text-white">
              <i className="fab fa-facebook-f"></i>
            </a>
            <a href="/#" className="hover:text-white">
              <i className="fab fa-twitter"></i>
            </a>
            <a href="/#" className="hover:text-white">
              <i className="fab fa-instagram"></i>
            </a>
          </div>
        </div>
      </footer>
    </div>
  );

  return (
    <>
      {loadPage ? (
        <Suspense fallback={<div>Loading...</div>}>
          {currentUser ? (
            <>
              <GoogleOneTap currentUser={currentUser} setCurrentUser={setCurrentUser} />
              <LandingPage currentUser={currentUser} />
            </>
          ) : (
            <Matrix />
          )}
        </Suspense>
      ) : (
        <MainPage />
      )}
      <style>

        {`
        .gradient-text {
background: linear-gradient(45deg, #ff9a8b, #ff6a88, #ff99ac, #ffd4e5);
  background-size: 300%;
  background-clip: text;
  -webkit-background-clip: text;
  color: transparent;
  animation: gradientAnimation 3s ease-in-out infinite;
}

@keyframes gradientAnimation {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

        nav ul li{
        
        padding:10px;

        }

        .page-bg {
  background-image: url('color-life.jpg');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

        nav a{
        
        padding:10px;

        }
        
        `}

      </style>
    </>
  );
}

export default App;
